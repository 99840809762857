import React from 'react';

import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/Sidebar';

import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';
import pic8 from '../assets/images/pic08.jpg';
import servicesBackground from '../assets/images/services-background.jpg';
import map from '../assets/images/map.png';
import Scroll from '../components/Scroll';

const sections = [
  { id: 'top', name: 'Главная', icon: 'fa-home' },
  { id: 'about', name: 'Услуги', icon: 'fa-user' },
  { id: 'portfolio', name: 'Галерея', icon: 'fa-th' },
  { id: 'contact', name: 'Контакты', icon: 'fa-envelope' },
];

const IndexPage = () => (
  <Layout>
    <SideBar sections={sections} />

    <div id="main">
      <section id="top" className="one dark cover">
        <div className="">
          <header>
            <h2 className="alt main-header">
              <strong>ООО «ДФ-Лоджистикс»</strong>
              <br/>
            </h2>

            <h2 className="alt main-header">
            <strong>Обработка груза на складах. Доставка до клиента.</strong>
            </h2>

          </header>

          <footer>
            <Scroll type="id" element={'about'}>
              <a href="#about" className="button">
                Наши услуги
              </a>
            </Scroll>
          </footer>
        </div>
      </section>

      <section id="about" className="three">
        <div className="container">
          <header>
            <h2>Услуги</h2>
          </header>

          <a className="image featured">
            <img src={servicesBackground} alt="" />
          </a>

          <p>
            ООО «ДФ-Лоджистикс» оказывает следующие услуги:
          </p>

          <ol className="services-list">
            <li><em>Приемка грузов</em> на паллетах, навалом</li>
            <li><em>Погрузочно-разгрузочные работы</em> (механизированные, ручные)</li>
            <li><em>Комплектация, подбор</em> (паллетами, коробами, штуками)</li>
            <li><em>Предпродажная подготовка товаров</em> (маркировка, стикерование, упаковка, переупаковка, вложение документации)</li>
            <li><em>Обработка товаров по специальным требованиям заказчика</em> (распаковка, проверка, сборка, переупаковка)</li>
            <li><em>Паллетирование и индивидуальное провешивание товаров</em></li>
            <li><em>Компьютеризированный учет движения грузов</em> и контроль товарных остатков</li>
            <li><em>Партионный учет</em></li>
            <li><em>Обработка бракованного товара, утилизация</em></li>
            <li><em>Оформление сопроводительной документации</em> на прием и отгрузку товара</li>
            <li><em>Предоставление отчетов о движении товаров</em></li>
          </ol>
        </div>
      </section>



      <section id="portfolio" className="four">
        <div className="container">
          <header>
            <h2>Галерея</h2>
          </header>

          <div className="row">
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a  className="image fit">
                  <img src={pic8} alt="" />
                </a>
                <header>
                  <h3>Склад "А"</h3>
                </header>
              </article>
              <article className="item">
                <a  className="image fit">
                  <img src={pic3} alt="" />
                </a>
                <header>
                  <h3>Погрузочно-разгрузочные работы</h3>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a  className="image fit">
                  <img src={pic4} alt="" />
                </a>
                <header>
                  <h3>Склад "В"</h3>
                </header>
              </article>
              <article className="item">
                <a  className="image fit">
                  <img src={pic2} alt="" />
                </a>
                <header>
                  <h3>Партионный учет</h3>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a  className="image fit">
                  <img src={pic6} alt="" />
                </a>
                <header>
                  <h3>Склад "С"</h3>
                </header>
              </article>
              <article className="item">
                <a  className="image fit">
                  <img src={pic7} alt="" />
                </a>
                <header>
                  <h3>Обработка по спец. требованиям заказчика</h3>
                </header>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="five">
        <div className="container">
          <header>
            <h2>Контакты</h2>
          </header>

          {/* <a href="/#" className="image featured">
            <img src={map} alt="" />
          </a> */}

          <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2235.0249618490943!2d37.855443815937505!3d55.93160518060155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b52defa8fef103%3A0x96c6e9445b8e5bc6!2sSvobodnaya%20Ulitsa%2C%201%D0%B0%2C%20Korolyov%2C%20Moskovskaya%20oblast&#39;%2C%20141090!5e0!3m2!1sen!2sru!4v1592232487664!5m2!1sen!2sru" width="600" height="450" frameborder="0" allowfullscreen="" ariaHidden="false" tabindex="0"></iframe>

          <p>
          <em>Юридический адрес:</em> 141060,
          Московская Область, г. Королев, микрорайон Болшево, Улица Свободная 1А, офис 3
          </p>
          <p><em>Контактный телефон:</em> +79258431418</p>
          <p><em>Электронная почта:</em> df.logistics.msk@gmail.com</p>
        </div>
      </section>
    </div>

    <PageFooter />
  </Layout>
);

export default IndexPage;
